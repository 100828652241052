import type { AppProps } from "next/app"
import { useEffect } from "react"
import { Space_Grotesk } from "next/font/google"
import { SessionProvider, useSession } from "next-auth/react"
import * as Sentry from "@sentry/nextjs"

import { trpc } from "../utils/trpc"
import "../styles/globals.css"

const spaceGrotesk = Space_Grotesk({ weight: "400", subsets: ["latin"] })

function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <main className={spaceGrotesk.className}>
      <SessionProvider session={session}>
        <Auth>
          <Component {...pageProps} />
        </Auth>
      </SessionProvider>
    </main>
  )
}

const Auth = ({ children }: { children: React.ReactNode }) => {
  const { status, data } = useSession({ required: true })

  useEffect(() => {
    if (data) {
      Sentry.setUser({
        id: data.user.id,
        email: data.user.email || undefined,
        username: data.user.name || undefined,
      })
    }
  }, [data])

  if (status === "loading") {
    return <div>Checking Auth Status...</div>
  }

  return <>{children}</>
}

export default trpc.withTRPC(App)
